@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body{
  background-color: #ECECEC;
  color: #2C2C54;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.container {
  height: 100dvh;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 1fr 5fr 1fr;
  grid-template-columns: 1fr;
  justify-content: space-between;
}

h1 {
  font-weight: 800;
  font-size: 1.5rem;
  padding-top: 1rem;
}

.combo-box{
  display: grid;
  justify-content: center;
  align-items: center;

}

#value-input {
  width: 50%;
  justify-self: center;
  margin-top: 0;
}

.calculation-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.calculation-area > div {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 2fr;
  align-items: start;
}

select {
  padding: 0.3rem;
  font-size: 0.8rem;
  color: #2C2C54;
  font-family: "Open Sans", sans-serif;
  border: none;
  width: 50%;
  justify-self: center;
  background-color: white;
}

h3 {
  font-size: 1rem;
}

label {
  display: grid;
}

#waterPressure {
  text-align: center;
  width: auto;
}

#pris-label{
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

#kroner-per-m > p{
  text-align: right;
  margin: 0;
}

#kroner-input{
  display: flex;
}

#kroner-input > input {
  margin: 0 0 0 1rem;
  text-align: left;
}

input {
  margin: 0.5rem;
  border: none;
  text-align: center;
  color: #474787;
  font-weight: 800;
  font-size: 1rem;
  width: 25%;
  padding: 0.5rem;
}

.utregning {
  margin: 0 2rem 0 2rem;
}

#annum-cost {
  margin-top: 3rem;
}

#total-cost {
  font-size: 1.2rem;
}

.footer {
  align-self: end;
  padding-bottom: 1rem;
}

.footer > p {
  font-size: 0.7rem;
}

a, a:visited {
  color: #2C2C54;
  text-decoration: none;
}

a:hover {
  color: #474787;
}

a:active {
  font-weight: bold;
  color: #2C2C54;
}